import { CSSProperties, FunctionComponent } from 'react';
import classNames from 'classnames';
import { Size } from '../../types';
import styles from './styles/styles.module.scss';
import IconColor from './IconColor';

type IconSize = Size<'xs' | 'sm' | 'md' | 'lg'>;

type IconOrientation = 'up' | 'right' | 'down' | 'left';

const iconOrientationToDeg: Record<IconOrientation, number> = {
  up: 0,
  right: 90,
  down: 180,
  left: 270
};

export type IconProps = {
  /**
   * Custom attributes to apply to the SVG element. This overrides the default fill and style properties.
   */
  className?: string;
  color?: IconColor | 'currentColor';
  orientation?: IconOrientation | number;
  size?: IconSize;
  style?: CSSProperties;
  svgAttributes?: Record<string, string>;
};

export type IconComponent = FunctionComponent<IconProps> & { iconName: string };

const Icon = (iconName: string, svgContents: JSX.Element): IconComponent => {
  const iconComponent = ({ className, color = '--color-text-primary', orientation = 0, size = 'md', style = {}, svgAttributes }: IconProps) => {
    const rotation = typeof orientation === 'number' ? orientation : iconOrientationToDeg[orientation];

    return (
      <svg
        className={classNames(styles.container, styles[size], className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        role="presentation"
        aria-hidden="true"
        focusable="false"
        data-testid={iconName}
        style={{
          transition: 'transform 200ms ease-in-out',
          ...(rotation !== 0 ? { transform: `rotate(${rotation}deg)` } : {}), // transform creates a new stacking context so avoid as much as possible
          ...(!svgAttributes
            ? {
                fill: color === 'currentColor' ? color : `var(${color})`,
                ...style
              }
            : {})
        }}
        {...svgAttributes}
      >
        {svgContents}
      </svg>
    );
  };

  return Object.assign(iconComponent, { iconName }); // Used for storybook naming
};

export default Icon;
