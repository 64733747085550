/**
 * IconDefinition & IconRenderer are a convenient way for a component to accept a Denali icon,
 * along with limited configurable properties, as a prop and render it with specified defaults.
 */
import { IconComponent, IconProps } from '../Icon';

/**
 * Properties that are configurable on the IconDefinition type
 */
type IconProperties<P extends keyof IconProps> = Partial<{
  [Prop in P]: IconProps[Prop];
}>;

/**
 * Creates a type where the Component is required but all of the specified IconProps are optional
 */
export type IconDefinition<P extends keyof IconProps> = {
  Component: IconComponent;
} & IconProperties<P>;

export type IconRendererProps = {
  icon: IconDefinition<keyof IconProps>;
  defaults: IconProperties<keyof IconProps>;
};

const IconRenderer = ({ icon, defaults }: IconRendererProps) => (
  <icon.Component
    color={icon.color || defaults.color}
    className={icon.className || defaults.className}
    orientation={icon.orientation || defaults.orientation}
    size={icon.size || defaults.size}
    style={icon.style || defaults.style}
    svgAttributes={icon.svgAttributes || defaults.svgAttributes}
  />
);

export default IconRenderer;
