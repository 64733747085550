/**
 * Generated file
 * Do not edit directly
 */

export const GRID_PAGE_MAX_WIDTH = 1280;
export const BREAKPOINT_XS_SIZE = 360;
export const BREAKPOINT_XS_MARGIN = 16;
export const BREAKPOINT_SM_SIZE = 760;
export const BREAKPOINT_SM_MARGIN = 24;
export const BREAKPOINT_MD_SIZE = 1120;
export const BREAKPOINT_MD_MARGIN = 48;
export const BREAKPOINT_LG_SIZE = 1920;
export const BREAKPOINT_LG_MARGIN = 80;
export const Z_INDEX_DEEP = -99999;
export const Z_INDEX_DEFAULT = 1;
export const Z_INDEX_STICKY = 100;
export const Z_INDEX_DROPDOWN = 200;
export const Z_INDEX_OVERLAY = 300;
export const Z_INDEX_MODAL = 400;
export const Z_INDEX_POPOVER = 800;
export const Z_INDEX_SPINNER = 900;
export const Z_INDEX_TOAST = 1000;
export const DURATION_SHORT = '300ms';
export const DURATION_DEFAULT = '500ms';
export const DURATION_LONG = '2000ms';
export const TIMING_EASE_IN = 'cubic-bezier(0.15, 0, 0.15, 0)';
export const TIMING_EASE_OUT = 'cubic-bezier(0.32, 0.94, 0.60, 1)';
export const TIMING_LINEAR = 'cubic-bezier(0, 0, 1, 1)';
export const BORDERS_WEIGHT_SM = 1;
export const BORDERS_WEIGHT_MD = 2;
export const BORDERS_WEIGHT_LG = 3;
export const FONT_FAMILY_PRIMARY = 'Manrope';
export const FONT_FAMILY_SECONDARY = 'Beatrice';
export const FONT_WEIGHT_NORMAL = 'normal';
export const FONT_WEIGHT_BOLD = 'bold';
export const FONT_LINEHEIGHT_400 = 1.2;
export const FONT_LINEHEIGHT_500 = 1.18;
export const FONT_LINEHEIGHT_600 = 1.15;
export const FONT_LINEHEIGHT_700 = 1.1;
export const FONT_LINEHEIGHT_700_RESPONSIVE = 1.06;
export const FONT_LINEHEIGHT_600_RESPONSIVE = 1.1;
export const FONT_LINEHEIGHT_500_RESPONSIVE = 1.15;
export const FONT_LINEHEIGHT_400_RESPONSIVE = 1.2;
export const FONT_LINEHEIGHT_DEFAULT = 1.5;
export const FONT_LINEHEIGHT_LOOSE = 1.7;
export const FONT_SIZE_50 = 11;
export const FONT_SIZE_100 = 13;
export const FONT_SIZE_200 = 16;
export const FONT_SIZE_300 = 20;
export const FONT_SIZE_400 = 24;
export const FONT_SIZE_500 = 28;
export const FONT_SIZE_600 = 32;
export const FONT_SIZE_700 = 44;
export const FONT_SIZE_400_RESPONSIVE = 28;
export const FONT_SIZE_500_RESPONSIVE = 32;
export const FONT_SIZE_600_RESPONSIVE = 36;
export const FONT_SIZE_700_RESPONSIVE = 60;
export const FONT_LETTERSPACING_400 = -0.25;
export const FONT_LETTERSPACING_500 = -0.5;
export const FONT_LETTERSPACING_600 = -0.5;
export const FONT_LETTERSPACING_700 = -1;
export const FONT_LETTERSPACING_700_RESPONSIVE = -1;
export const FONT_LETTERSPACING_600_RESPONSIVE = -1;
export const FONT_LETTERSPACING_500_RESPONSIVE = -0.5;
export const FONT_LETTERSPACING_400_RESPONSIVE = -0.5;
export const RADIUS_NONE = 0;
export const RADIUS_SM = 4;
export const RADIUS_MD = 8;
export const RADIUS_LG = 16;
export const RADIUS_XL = 24;
export const RADIUS_ROUND = 9999;
export const RADIUS_SHEET = 16;
export const RADIUS_CARD = 16;
export const SHADOW_100 = '0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 0px 1px 0px rgba(0, 0, 0, 0.30)';
export const SHADOW_200 = '0px 4px 8px 0px rgba(0, 0, 0, 0.18), 0px 0px 1px 0px rgba(0, 0, 0, 0.30)';
export const SHADOW_250 = '0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.12)';
export const SHADOW_300 = '0px 8px 12px 0px rgba(0, 0, 0, 0.15), 0px 0px 1px 0px rgba(0, 0, 0, 0.30)';
export const SIZE_ICON_XS = 16;
export const SIZE_ICON_SM = 20;
export const SIZE_ICON_MD = 24;
export const SIZE_ICON_LG = 32;
export const SIZE_CONTROLS_SM = 36;
export const SIZE_CONTROLS_MD = 48;
export const SIZE_CONTROLS_LG = 56;
export const SIZE_BUTTON_RADIUS_SM = 9999;
export const SIZE_BUTTON_RADIUS_MD = 9999;
export const SIZE_BUTTON_RADIUS_LG = 9999;
export const SIZE_HEADER_HEIGHT_DESKTOP = 64;
export const SIZE_HEADER_HEIGHT_MOBILE = 64;
export const SPACE_0 = 4; // deprecate
export const SPACE_4 = 4; // deprecate
export const SPACE_8 = 8; // deprecate
export const SPACE_12 = 12; // deprecate
export const SPACE_16 = 16; // deprecate
export const SPACE_24 = 24; // deprecate
export const SPACE_32 = 32; // deprecate
export const SPACE_40 = 40; // deprecate
export const SPACE_48 = 48; // deprecate
export const SPACE_50 = 4;
export const SPACE_64 = 64; // deprecate
export const SPACE_96 = 96; // deprecate
export const SPACE_100 = 8;
export const SPACE_128 = 128; // deprecate
export const SPACE_150 = 12;
export const SPACE_200 = 16;
export const SPACE_300 = 24;
export const SPACE_450 = 36;
export const SPACE_600 = 48;
export const SPACE_800 = 64;
export const SPACE_1200 = 96;
export const SPACE_HORIZONTAL_SCREEN_MOBILE = 24;
export const SPACE_PAGE_HORIZONTAL_MOBILE = 24;
