'use client';

// eslint-disable-next-line @alltrails/custom/client-navigation
import { useParams } from 'next/navigation';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import Locale from '@alltrails/shared/types/Locale';
import { getLanguageRegionCodeFromLocale } from '@alltrails/shared/utils/languageSupport';
import { useRouter } from '@alltrails/core';

type Params = {
  locale: Locale;
};

export function useLanguageRegionCode() {
  const router = useRouter();
  const params = useParams<Params>();

  const locale = router ? (router.locale as Locale) : params?.locale;

  return getLanguageRegionCodeFromLocale(locale) || LanguageRegionCode.English;
}
