import type { PointLike } from 'react-map-gl';
import type { BaseStyleId, ConditionStyleId, DynamicStyleId } from '../types/Styles';

// Eventually we may want multiple maps on a page. For now just the one default.
export const mapId = 'defaultMap';
export const defaultBaseStyleId: BaseStyleId = 'alltrailsOutdoorsV2';

export const mapControlsTopRightContainerId = 'map-controls-container';

// Dynamic style ids that are supported by default on all (most) maps.
// Individual maps can decide to add/remove from this when rendering BaseMap via the supportedDynamicStyleIds prop.
export const defaultSupportedDynamicStyleIds: DynamicStyleId[] = [
  'after',
  'heatmap',
  'limit',
  'mapsAll',
  'newHeatmap',
  'pendingTrails',
  'recordingsAll',
  'recordingsPop'
];

export const conditionsStyleIds: ConditionStyleId[] = ['precipitation', 'snowDepth', 'temperature'];

export const defaultClusterRadius = 50;
export const defaultPitchFor3dTerrain = 65;
export const maxPitch = 60;
export const maxPitchFor3dTerrain = 85;
export const maxMapboxZoom = 22;

export const trailheadMarkerOffset = [0, -16.5] as PointLike;

export const trailCardOffset = {
  center: [0, 0] as PointLike,
  left: [22, 19] as PointLike,
  right: [-22, 19] as PointLike,
  top: [0, 7] as PointLike,
  bottom: [0, -46] as PointLike,
  'top-right': [-22, -38] as PointLike,
  'top-left': [22, -38] as PointLike,
  'bottom-left': [22, 0] as PointLike,
  'bottom-right': [-22, 0] as PointLike
};
// offsetting the trail card when a trailhead pin is hovered/clicked:
export const trailCardTrailheadOffset = {
  center: [0, 0] as PointLike,
  left: [64, 17] as PointLike,
  right: [-64, 17] as PointLike,
  top: [0, 10] as PointLike,
  bottom: [0, -49] as PointLike,
  'top-right': [-64, -42] as PointLike,
  'top-left': [64, -42] as PointLike,
  'bottom-left': [64, 2] as PointLike,
  'bottom-right': [-64, 2] as PointLike
};

export const defaultPadding = {
  top: 20,
  bottom: 20,
  right: 20,
  left: 20
};
