import { type ReactNode, type MouseEvent, type KeyboardEventHandler, type Ref, forwardRef } from 'react';
import type { LinkInfo } from '../../types';
import LinkOrNativeAnchor from '../LinkOrNativeAnchor/LinkOrNativeAnchor';

type LinkInfoRendererProps = {
  ariaLabel?: string;
  children: ReactNode;
  className?: string;
  linkInfo: LinkInfo;
  onBlur?: () => void;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  onContextMenu?: (e: MouseEvent<HTMLElement>) => void;
  onFocus?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLElement>;
  onMouseEnter?: (e: MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (e: MouseEvent<HTMLElement>) => void;
  role?: string;
  tabIndex?: number;
  testId?: string;
  title?: string;
};

const LinkInfoRenderer = (
  {
    ariaLabel,
    children,
    className,
    linkInfo,
    onBlur,
    onClick,
    onContextMenu,
    onFocus,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    role,
    tabIndex = 0,
    testId,
    title
  }: LinkInfoRendererProps,
  ref: Ref<HTMLAnchorElement>
): JSX.Element => {
  const { rel, target, href, outsideOfMugen, prefetch } = linkInfo;
  const linkProps = {
    'aria-label': ariaLabel,
    className,
    'data-testid': testId,
    href,
    onBlur,
    onClick,
    onContextMenu,
    onFocus,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    ref,
    rel,
    role,
    target,
    tabIndex,
    title,
    // Default to false unless explicitly set to true
    prefetch: prefetch ? prefetch : false
  };

  return (
    <LinkOrNativeAnchor outsideOfMugen={outsideOfMugen} {...linkProps}>
      {children}
    </LinkOrNativeAnchor>
  );
};

export default forwardRef(LinkInfoRenderer);
