/**
 * Generated file
 * Do not edit directly
 */

export const COLOR_BRAND_PRIMARY = '#2c5601';
export const COLOR_BRAND_DARK = '#142800';
export const COLOR_BRAND_NEON = '#64f67b';
export const COLOR_BRAND_CREAM = '#f7f4ed';
export const COLOR_BRAND_ONYX = '#161f13';
export const COLOR_BRAND_PINE = '#1b2818';
export const COLOR_BRAND_LAVENDER = '#d7cadb';
export const COLOR_BRAND_DUSK = '#9889b3';
export const COLOR_BRAND_FOG = '#dce1db';
export const COLOR_BRAND_MOSS = '#7d867c';
export const COLOR_BRAND_SAND = '#c1af98';
export const COLOR_BRAND_BIRCH = '#f2eee7';
export const COLOR_NEUTRAL_50 = '#f6f6f4';
export const COLOR_NEUTRAL_100 = '#efefec';
export const COLOR_NEUTRAL_200 = '#dbdad2';
export const COLOR_NEUTRAL_300 = '#c2c2b8';
export const COLOR_NEUTRAL_400 = '#a7a99f';
export const COLOR_NEUTRAL_500 = '#8b9182';
export const COLOR_NEUTRAL_600 = '#656e5e';
export const COLOR_NEUTRAL_700 = '#4c5c43';
export const COLOR_NEUTRAL_800 = '#2b381f';
export const COLOR_NEUTRAL_900 = '#1c2513';
export const COLOR_NEUTRAL_950 = '#10130b'; // this is only for dark mode and shouldn't be used directly in light mode
export const COLOR_NEUTRAL_WHITE = '#ffffff';
export const COLOR_NEUTRAL_BLACK = '#000000';
export const COLOR_NEUTRAL_TRANSPARENT = 'rgba(0, 0, 0, 0)';
export const COLOR_NEUTRAL_50_A = 'rgba(21, 27, 13, 0.04)';
export const COLOR_NEUTRAL_100_A = 'rgba(21, 27, 13, 0.08)';
export const COLOR_NEUTRAL_200_A = 'rgba(21, 27, 13, 0.16)';
export const COLOR_NEUTRAL_300_A = 'rgba(21, 27, 13, 0.27)';
export const COLOR_NEUTRAL_400_A = 'rgba(21, 27, 13, 0.38)';
export const COLOR_NEUTRAL_500_A = 'rgba(21, 27, 13, 0.49)';
export const COLOR_NEUTRAL_600_A = 'rgba(21, 27, 13, 0.64)';
export const COLOR_NEUTRAL_700_A = 'rgba(21, 27, 13, 0.74)';
export const COLOR_NEUTRAL_800_A = 'rgba(21, 27, 13, 0.88)';
export const COLOR_NEUTRAL_900_A = 'rgba(21, 27, 13, 0.9)';
export const COLOR_GREEN_100 = '#e9faec';
export const COLOR_GREEN_300 = '#a2e4a0';
export const COLOR_GREEN_700 = '#2f751a';
export const COLOR_GREEN_900 = '#1c2513';
export const COLOR_GREEN_BASE = '#51c152';
export const COLOR_RED_100 = '#ffe6df';
export const COLOR_RED_300 = '#fdaa9b';
export const COLOR_RED_700 = '#b24b34';
export const COLOR_RED_900 = '#38201a';
export const COLOR_RED_BASE = '#f87559';
export const COLOR_BLUE_100 = '#e2eff2';
export const COLOR_BLUE_300 = '#83c9dc';
export const COLOR_BLUE_700 = '#196b8d';
export const COLOR_BLUE_900 = '#19252a';
export const COLOR_BLUE_BASE = '#51aec8';
export const COLOR_YELLOW_100 = '#fdf0d5';
export const COLOR_YELLOW_300 = '#fccc78';
export const COLOR_YELLOW_700 = '#925e0c';
export const COLOR_YELLOW_900 = '#3a2a0e';
export const COLOR_YELLOW_BASE = '#eea82f';
export const COLOR_PURPLE_100 = '#d7cadb';
export const COLOR_PURPLE_BASE = '#a386aa';
export const COLOR_ORANGE_100 = '#f9d9b3';
export const COLOR_ORANGE_300 = '#f0c389';
export const COLOR_ORANGE_BASE = '#f39d4f';
export const COLOR_PLUS_100 = '#ebeeff';
export const COLOR_PLUS_300 = '#a3b2ff';
export const COLOR_PLUS_500 = '#5772ff';
export const COLOR_PLUS_600 = '#4563ff';
export const COLOR_PLUS_700 = '#415096';
export const COLOR_PLUS_900 = '#171e40';
export const COLOR_PEAK_200 = '#efe7fe';
export const COLOR_PEAK_400 = '#c09afb';
export const COLOR_PEAK_500 = '#ac76f6';
export const COLOR_PEAK_600 = '#8456c5';
export const COLOR_PEAK_800 = '#44345f';
export const COLOR_UI_HIGHLIGHT = '#64f67b';
export const COLOR_UI_HIGHLIGHT_HOVER = '#4ade61';
export const COLOR_TEXT_PRIMARY = '#142800';
export const COLOR_TEXT_PRIMARY_HOVER = '#2b381f';
export const COLOR_TEXT_SECONDARY = '#656e5e';
export const COLOR_TEXT_SECONDARY_HOVER = '#4c5c43';
export const COLOR_TEXT_TERTIARY = '#8b9182';
export const COLOR_TEXT_PRIMARY_INVERSE = '#ffffff';
export const COLOR_TEXT_INVERSE_HOVER = '#ffffff';
export const COLOR_TEXT_DISABLED = '#dbdad2';
export const COLOR_TEXT_PLACEHOLDER = '#656e5e';
export const COLOR_TEXT_ERROR = '#b24b34';
export const COLOR_TEXT_SUCCESS = '#2f751a';
export const COLOR_TEXT_CAUTION = '#925e0c';
export const COLOR_TEXT_INFO = '#196b8d';
export const COLOR_TEXT_PLUS = '#4563ff';
export const COLOR_TEXT_PEAK = '#ac76f6';
export const COLOR_TEXT_LIGHT = '#ffffff';
export const COLOR_TEXT_LIGHT_SECONDARY = 'rgba(255, 255, 255, 0.7)';
export const COLOR_TEXT_DARK = '#142800';
export const COLOR_TEXT_LINK_PRIMARY = '#142800'; // deprecate, use text tokens instead
export const COLOR_TEXT_LINK_PRIMARY_HOVER = '#2b381f';
export const COLOR_TEXT_LINK_SECONDARY = '#142800'; // deprecate, use text tokens instead
export const COLOR_TEXT_LINK_SECONDARY_HOVER = '#2b381f'; // deprecate, use text tokens instead
export const COLOR_TEXT_LINK_INVERSE = '#ffffff'; // deprecate, use text tokens instead
export const COLOR_TEXT_LINK_INVERSE_HOVER = '#efefec'; // deprecate, use text tokens instead
export const COLOR_BACKGROUND_PRIMARY = '#ffffff';
export const COLOR_BACKGROUND_PRIMARY_HOVER = '#f6f6f4';
export const COLOR_BACKGROUND_SECONDARY = '#efefec';
export const COLOR_BACKGROUND_SECONDARY_HOVER = '#dbdad2';
export const COLOR_BACKGROUND_TERTIARY = '#dbdad2';
export const COLOR_BACKGROUND_ELEVATED = '#ffffff';
export const COLOR_BACKGROUND_ACCENT = '#64f67b';
export const COLOR_BACKGROUND_ACCENT_HOVER = '#4ade61';
export const COLOR_BACKGROUND_DISABLED = '#f6f6f4';
export const COLOR_BACKGROUND_PLUS = '#ebeeff';
export const COLOR_BACKGROUND_PLUS_SECONDARY = '#a3b2ff';
export const COLOR_BACKGROUND_PEAK = '#efe7fe';
export const COLOR_BACKGROUND_SUCCESS_WEAK = '#e9faec';
export const COLOR_BACKGROUND_SUCCESS_BASE = '#51c152';
export const COLOR_BACKGROUND_ERROR_WEAK = '#ffe6df';
export const COLOR_BACKGROUND_ERROR_BASE = '#b24b34';
export const COLOR_BACKGROUND_CAUTION_WEAK = '#fdf0d5';
export const COLOR_BACKGROUND_CAUTION_BASE = '#eea82f';
export const COLOR_BACKGROUND_INFO_WEAK = '#e2eff2';
export const COLOR_BACKGROUND_INFO_BASE = '#51aec8';
export const COLOR_BACKGROUND_ACCENT_WEAK = '#f7f4ed';
export const COLOR_BACKGROUND_ACCENT_BASE = '#2c5601';
export const COLOR_BACKGROUND_ACCENT_STRONG = '#142800';
export const COLOR_BACKGROUND_INVERSE = '#142800'; // this should replace bg primary inverse overlay
export const COLOR_BACKGROUND_TINTED = 'rgba(255, 255, 255, 0.7)';
export const COLOR_BACKGROUND_TINTED_INVERSE = 'rgba(25, 32, 19, 0.08)';
export const COLOR_BACKGROUND_PRIMARY_OVERLAY = 'rgba(255, 255, 255, 0.8)'; // For use in translucent buttons, toasts, tags etc.
export const COLOR_BACKGROUND_PRIMARY_INVERSE_OVERLAY = 'rgba(18, 18, 18, 0.85)'; // use bg.inverse instead and set opacity separately
export const COLOR_BORDER_SEPARATOR = '#dbdad2';
export const COLOR_BORDER_INTERACTIVE = '#c2c2b8';
export const COLOR_BORDER_ACTIVE = '#142800';
export const COLOR_BORDER_INTERACTIVE_ACCENT = '#142800';
export const COLOR_BORDER_INTERACTIVE_ACTIVE = '#142800';
export const COLOR_BORDER_INTERACTIVE_HOVER = '#a7a99f';
export const COLOR_BORDER_DISABLED = '#dbdad2';
export const COLOR_BORDER_ERROR = '#b24b34';
export const COLOR_BORDER_FOCUS_PRIMARY = 'rgba(110, 221, 127, 0.7)'; // for web focus states that match primary ctas
export const COLOR_BORDER_FOCUS_DEFAULT = 'rgba(101, 110, 94, 0.7)'; // for web focus states that match default and non-primary ctas
export const COLOR_SHADOW = 'rgba(0, 0, 0, 0.3)';
export const COLOR_INTERACTIVE_ACCENT = '#142800'; // interactive items that become neon in dark mode
export const COLOR_INTERACTIVE_PRIMARY = '#264311';
export const COLOR_INTERACTIVE_DEFAULT = '#142800'; // where used as foreground, deprecate for in favor of text tokens
export const COLOR_INTERACTIVE_SUBTLE = '#656e5e'; // where used as foreground, deprecate for in favor of text tokens
export const COLOR_INTERACTIVE_ACCENT_HOVER = '#51c152';
export const COLOR_INTERACTIVE_PRIMARY_HOVER = '#2b381f';
export const COLOR_INTERACTIVE_DEFAULT_HOVER = '#2b381f'; // where used as foreground, deprecate for in favor of text tokens
export const COLOR_INTERACTIVE_SUBTLE_HOVER = '#4c5c43'; // where used as foreground, deprecate for in favor of text tokens
export const COLOR_DIFFICULTY_EASY = '#2f751a';
export const COLOR_DIFFICULTY_MODERATE = '#196b8d';
export const COLOR_DIFFICULTY_HARD = '#b24b34';
export const COLOR_UV_1 = '#a2e4a0';
export const COLOR_UV_2 = '#fccc78';
export const COLOR_UV_3 = '#f39d4f';
export const COLOR_UV_4 = '#f87559';
export const COLOR_UV_5 = '#a386aa';
export const COLOR_BADGE_COMPLETE = '#2b381f';
export const COLOR_BADGE_VERIFIED = '#2b381f';
export const COLOR_MAP_ROUTE = '#64f67b'; // Represents AllTrails specified route information, like route polyline, route elevation etc
export const COLOR_MAP_ROUTE_OUTLINE = '#2f751a'; // border for route
export const COLOR_MAP_ACTIVITY = '#219ad8'; // Represents users activity data, like the path a user has taken during an activity
export const COLOR_MAP_ACTIVITY_OUTLINE = '#24505e'; // Custom color. Border for activity
export const COLOR_MAP_WAYPOINT_VERIFIED = '#637acf';
export const COLOR_MAP_WAYPOINT_USER = '#889ada';
export const COLOR_MAP_WAYPOINT_PHOTO = '#a581b1';
export const COLOR_MAP_CUSTOM_ROUTE_1 = '#f87559';
export const COLOR_MAP_CUSTOM_ROUTE_2 = '#51aec8';
export const COLOR_MAP_CUSTOM_ROUTE_3 = '#eea82f';
export const COLOR_MAP_CUSTOM_ROUTE_4 = '#a57ed8';
export const COLOR_MAP_CUSTOM_ROUTE_1_OUTLINE = '#b24b34';
export const COLOR_MAP_CUSTOM_ROUTE_2_OUTLINE = '#196b8d';
export const COLOR_MAP_CUSTOM_ROUTE_3_OUTLINE = '#925e0c';
export const COLOR_MAP_CUSTOM_ROUTE_4_OUTLINE = '#69546d';
export const COLOR_MAP_POI = '#e9faec'; // when a trail intersects a POI
export const COLOR_MAP_POI_OUTLINE = '#2f751a'; // Border for POI
export const COLOR_MAP_CONDITION_OUTLINE = '#000000'; // Border for polyline with conditions overlay
export const COLOR_CONDITIONS_GRASS = '#85b767';
export const COLOR_CONDITIONS_WOOD = '#805c5c';
export const COLOR_CONDITIONS_NATURAL = '#af8657';
export const COLOR_CONDITIONS_SAND = '#e5bf79';
export const COLOR_CONDITIONS_GRAVEL = '#d4d2bf';
export const COLOR_CONDITIONS_ROCK = '#8a8783';
export const COLOR_CONDITIONS_PAVED = '#555f52';
export const COLOR_CONDITIONS_METAL = '#acbec3';
export const COLOR_CONDITIONS_SNOW = '#92d8f5';
export const COLOR_CONDITIONS_PLASTIC = '#6174cb';
export const COLOR_CONDITIONS_RUBBER = '#9258ae';
export const COLOR_CONDITIONS_STEPS = '#c25c5b';
export const COLOR_DATAVIZ_1 = '#3571ca';
export const COLOR_SCRIM_LIGHT = 'rgba(0, 0, 0, 0.4)';
export const COLOR_SCRIM_DARK = 'rgba(0, 0, 0, 0.7)';
export const COLOR_VENDOR_FACEBOOK = '#3c5997';
export const COLOR_VENDOR_GOOGLE = '#4184f3';
export const COLOR_VENDOR_APPLE = '#000000';
export const GUIDE_BLACKFOREST_BACKGROUND = '#131E0E';
export const GUIDE_BLACKFOREST_BLOCKQUOTE = '#ECF9EE';
export const GUIDE_EVERGREEN_BACKGROUND = '#142800';
export const GUIDE_EVERGREEN_BLOCKQUOTE = '#ECF9EE';
export const GUIDE_MAROON_BACKGROUND = '#38201a';
export const GUIDE_MAROON_BLOCKQUOTE = '#FFE0E0';
export const GUIDE_BROWN_BACKGROUND = '#3a2a0e';
export const GUIDE_BROWN_BLOCKQUOTE = '#F9DAB4';
export const GUIDE_GOLDENROD_BACKGROUND = '#845B15';
export const GUIDE_GOLDENROD_BLOCKQUOTE = '#FDF3E2';
export const GUIDE_REDWOOD_BACKGROUND = '#9A4020';
export const GUIDE_REDWOOD_BLOCKQUOTE = '#ffe6df';
export const GUIDE_MIDNIGHT_BACKGROUND = '#19252a';
export const GUIDE_MIDNIGHT_BLOCKQUOTE = '#e2eff2';
