import useApexDomain from '@alltrails/context/hooks/useApexDomain';
import isAbsoluteUrl from './isAbsoluteLink';

export default function useIsExternalLink(href?: string) {
  const internalDomain = useApexDomain();

  if (!href) {
    return false;
  }

  return isAbsoluteUrl(href) && !href.includes(internalDomain);
}
