import { createSlice } from '@reduxjs/toolkit';
import type Plan from '@alltrails/core/types/Plan';

const planSlice = createSlice({
  name: 'plan',
  initialState: {} as Plan,
  reducers: {}
});

export default planSlice.reducer;
