import { createSlice } from '@reduxjs/toolkit';
import type Plan from '@alltrails/core/types/Plan';

const planSlice = createSlice({
  name: 'plans',
  initialState: {} as { peak: Plan; plus: Plan },
  reducers: {}
});

export default planSlice.reducer;
